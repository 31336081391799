import React from 'react'
import {graphql} from 'gatsby'
import Helmet from 'react-helmet'
import Form from 'react-netlify-form'

import Layout from '../layout'
import SEO from '../components/seo'
import {DangerousHTML} from '../components/contentful/html'
import {TextInput, Textarea} from '../components/forms'

const ContactPage = ({data}) => (
	<Layout>
		<Helmet>
			<body className="contact-page" />
		</Helmet>
		<SEO {...data.page?.metadata} />
		<div className="inset contact">
			<DangerousHTML>{data.page?.body.html.content}</DangerousHTML>
			{data.page?.body.html.content !== `` && <hr />}
			<Form
				name="contact"
				recaptcha={{sitekey: `6LeHEo8UAAAAABzLXIALQwr-leMdWYQ2pYiTlTu0`}}
			>
				{({loading, error, success, recaptcha, recaptchaError}) => (
					<React.Fragment>
						<TextInput
							name="name"
							label="Name"
							placeholder="Your Name"
							required
							disabled={loading || success}
						/>
						<TextInput
							name="email"
							label="Email"
							placeholder="Your Email"
							required
							disabled={loading || success}
						/>
						<TextInput
							name="subject"
							label="Subject"
							placeholder="Subject Message"
							required
							disabled={loading || success}
						/>
						<Textarea
							name="message"
							label="Message"
							placeholder="Your Message..."
							required
							disabled={loading || success}
						/>
						{recaptcha}
						<div className="block-button-container">
							<button
								disabled={loading || success}
								type="submit"
								className="button"
							>
								Send Message
							</button>
						</div>

						{loading && <p className="submission-feedback">Loading...</p>}
						{error && (
							<p className="submission-feedback failure">
								Your information was not sent. Please try again.
							</p>
						)}
						{recaptchaError && (
							<p className="submission-feedback failure">
								Recaptcha did not match. Please make sure the box is checked.
							</p>
						)}
						{success && (
							<p className="submission-feedback">
								Thank you for contacting us!
							</p>
						)}
					</React.Fragment>
				)}
			</Form>
		</div>
	</Layout>
)

export default ContactPage

export const query = graphql`
	{
		page: contentfulBasicPage(title: {eq: "Contact"}) {
			body {
				html: childMarkdownRemark {
					content: html
				}
			}
			metadata {
				...ContentfulMetadataFragment
			}
		}
	}
`

import React from 'react'
import PropTypes from '../prop-types'

const inputPropTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
}

export const TextInput = props => (
	<Input {...props} Component="input" inputProps={{type: `text`}} />
)

TextInput.propTypes = inputPropTypes

export const Textarea = props => (
	<Input {...props} Component="textarea" inputProps={{rows: `4`}} />
)

Textarea.propTypes = inputPropTypes

const Input = ({
	name,
	label,
	placeholder,
	required,
	disabled,
	Component,
	inputProps,
}) => (
	<div className="form-group">
		<label for={name} className="control-label">
			{label}
		</label>
		<div className="col-lg-10">
			<Component
				{...inputProps}
				className="form-input"
				id={name}
				name={name}
				placeholder={placeholder}
				required={required}
				disabled={disabled}
			/>
		</div>
	</div>
)
